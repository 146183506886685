<template>
  <div class="auth-container">
    <ContentTitle>授权信息配置</ContentTitle>
    <Wfilter
      @handleSearch="handleSearch"
      @openDilatationDialog="openDilatationDialog"
      @openDelayDialog="openDelayDialog"
      :filterConfig="filterConfig"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :showPagin="true"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
    >
    </Wtable>
  </div>
</template>
<script>
import { ref } from "vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./auth.json";

export default {
  components: {
    Wtable,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const columns = config.tableConfig.list;
    const filterConfig = config.filterConfig;
    function handleSearch(v) {
      console.log(v);
    }
    const tableData = ref([
      {
        expirationCompany: "鲁滨逊的授权企业",
        ratio: "20/2",
        expirationLane: "车道名称这么漫长",
        expirationTime: "2021.01.29 12：23",
        activeTime: "2021.01.29 12：23",
      },
    ]);

    const pagination = ref({ total: 9 });
    const currentChange = (page) => {
      console.log(page);
    };
    function openDilatationDialog() {}
    function openDelayDialog() {}
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      handleSearch,
      filterConfig,
      openDilatationDialog,
      openDelayDialog,
    };
  },
};
</script>

<style lang='scss'>
.auth-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
}
</style>